import { Box, Button, Link } from '@mui/material';
import { Body1, H3, Subtitle1 } from './Typography';
import emptyState from '../../public/illustrations/empty-state.json';
import emptyFilterState from '../../public/illustrations/empty-filter.json';
import { EmptySidebarMessages, EmptyStateSidebarTabs } from '../lib/sidebar';
import dynamic from 'next/dynamic';
import { useParentSize } from '@visx/responsive';
import { useMemo } from 'react';

const LottiePlayer = dynamic(() => import('lottie-react'), { ssr: false });

/** maximum dimensions on lottie */
const MAX_DIM = 400;

export default function EmptyState({ path }: { path: EmptyStateSidebarTabs }) {
  const {
    parentRef: lottieRef,
    width: widthLottie,
    height: heightLottie
  } = useParentSize();

  /** our lottie is square, pick the smaller dimension */
  const squareDim = useMemo(() => {
    return Math.min(widthLottie, heightLottie);
  }, [widthLottie, heightLottie]);

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: 650, // Matches the width of the table we are replacing
        border: `1px solid ${theme.palette.grey[200]}`,
        borderRadius: theme.spacing(1.5),
        gap: theme.spacing(1.5),
        height: '100%',
        py: 1
      })}
      data-testid="EmptyState-root"
    >
      {path === EmptyStateSidebarTabs.NOT_FOUND ? (
        <LottiePlayer
          animationData={emptyFilterState}
          style={{ width: 96, height: 96 }}
        />
      ) : (
        <Box
          ref={lottieRef}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flex: 1,
            width: '100%',
            maxWidth: MAX_DIM,
            maxHeight: MAX_DIM
          }}
        >
          <LottiePlayer
            animationData={emptyState}
            style={{
              height: squareDim,
              width: squareDim
            }}
          />
        </Box>
      )}
      <Box
        sx={(theme) => ({
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
          width: 216
        })}
      >
        <H3 lineHeight={'32px'} textAlign={'center'}>
          {EmptySidebarMessages[path].title}
        </H3>
        <Subtitle1 lineHeight={'20px'} textAlign={'center'}>
          {EmptySidebarMessages[path].description}
        </Subtitle1>
      </Box>
      <IntegrationButtons path={path} />
    </Box>
  );
}

export function IntegrationButtons({ path }: { path: EmptyStateSidebarTabs }) {
  return (
    EmptySidebarMessages[path].buttonHref && (
      <Button
        href={EmptySidebarMessages[path].buttonHref}
        LinkComponent={Link}
        color="primary"
        startIcon={EmptySidebarMessages[path].buttonStartIcon ?? null}
        endIcon={EmptySidebarMessages[path].buttonEndIcon ?? null}
      >
        <Body1 lineHeight={'20px'}>
          {EmptySidebarMessages[path].buttonText}
        </Body1>
      </Button>
    )
  );
}

export const testIds = {
  emptyState: 'EmptyState-root'
};
