import { ReactElement } from 'react';
import { ArrowUpRightIcon, PlusIcon } from '../icons';
import {
  DirectoryPath,
  IntegrationsConnectPath,
  IntegrationsPath
} from './paths';

export enum EmptyStateSidebarTabs {
  ALERTS = 'ALERTS',
  DIRECTORY = 'DIRECTORY',
  RESOURCES = 'RESOURCES',
  SERVICES = 'SERVICES',
  INTEGRATIONS = 'INTEGRATIONS',
  NOT_FOUND = 'NOT_FOUND'
}

export const EmptySidebarMessages: Record<
  EmptyStateSidebarTabs,
  {
    title: string;
    description: string;
    buttonText?: string;
    buttonStartIcon?: ReactElement;
    buttonEndIcon?: ReactElement;
    buttonHref?: string;
  }
> = {
  [EmptyStateSidebarTabs.ALERTS]: {
    title: 'No Issues detected',
    description: 'Natoma will continuously monitor for new issues'
  },
  [EmptyStateSidebarTabs.DIRECTORY]: {
    title: 'No identities found',
    description: 'Automatically discover identities using our integrations',
    buttonText: 'Go to Integrations',
    buttonEndIcon: <ArrowUpRightIcon />,
    buttonHref: IntegrationsPath
  },
  [EmptyStateSidebarTabs.RESOURCES]: {
    title: 'No resources found',
    description: 'Automatically discover resources using our integrations',
    buttonText: 'Go to Integrations',
    buttonEndIcon: <ArrowUpRightIcon />,
    buttonHref: IntegrationsPath
  },
  [EmptyStateSidebarTabs.SERVICES]: {
    title: 'No services found',
    description: 'Automatically discover services using our integrations',
    buttonText: 'Go to Integrations',
    buttonEndIcon: <ArrowUpRightIcon />,
    buttonHref: IntegrationsPath
  },
  [EmptyStateSidebarTabs.INTEGRATIONS]: {
    title: 'No integrations found',
    description: 'Automatically discover identities using our integrations',
    buttonText: 'Add Integration',
    buttonStartIcon: <PlusIcon />,
    buttonHref: IntegrationsConnectPath
  },
  [EmptyStateSidebarTabs.NOT_FOUND]: {
    title: 'Page not found',
    description: 'The page you requested could not be found.',
    buttonText: 'Go to Home',
    buttonEndIcon: <ArrowUpRightIcon />,
    buttonHref: DirectoryPath
  }
};
